<template>
  <div class="mb-4">
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      class="wizard-vertical mb-3 card__"
    >
      <!-- Datos del client tab -->
      <tab-content
        title="Datos del cliente"
        :before-change="() => validateStep('step1')"
      >
        <customer-data-form
          ref="step1"
          :countries="countries"
          :departaments="departaments"
          :cities="cities"
          :record="customerRecord"
          :is-disable="isDisable"
          @on-validate="customerData"
          @loadCities="loadCities"
        />
      </tab-content>

      <!-- Configuración tab -->
      <tab-content
        title="Configuración"
        :before-change="() => validateStep('step2')"
      >
        <client-configuration-form
          ref="step2"
          :record="clientRecord"
          :user-email="userEmail"
          @on-validate="configurationData"
        />
      </tab-content>
      <template
        slot="footer"
        scope="props"
      >
        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex > 0 && !props.isLastStep"
            :style="props.fillButtonStyle"
            @click.native="props.prevTab()"
          >
            Atràs
          </wizard-button>
        </div>
        <div class="wizard-footer-right">
          <wizard-button
            v-if="!props.isLastStep"
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
            @click.native="props.nextTab()"
          >
            Siguiente
          </wizard-button>

          <wizard-button
            v-else
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            @click.native="validateStep('step2')"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            {{ props.isLastStep ? 'Guardar' : 'Siguiente' }}
          </wizard-button>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BSpinner } from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import CustomerDataForm from './components/addForm/CustomerDataForm'
import ClientConfigurationForm from './components/addForm/ClientConfigurationForm'

export default {
	inject: ['clientRepository', 'userRepository', 'headquarterRepository'],
	components: {
		FormWizard,
		TabContent,
		WizardButton,
		BSpinner,
		CustomerDataForm,
		ClientConfigurationForm,
	},
	props: {},
	data() {
		return {
			formData: {},
			repository: 'clientRepository',
			isBusy: false,
			countries: [],
			departaments: [],
			cities: [],
			isDisable: true,
			uuid: this.$route.params.uuid ? this.$route.params.uuid : null,
			customerRecord: null,
			clientRecord: null,
			headquarter: {},
			user: {},
			client: {},
			userEmail: null,
		}
	},
	created() {
		const me = this
		me.loadCountries()
		me.loadDepartaments()

		if (me.uuid) {
			me.loadClient(me.uuid)
		} else {
			me.customerRecord = null
			me.clientRecord = null
		}
	},
	methods: {
		validateStep(name) {
			const refToValidate = this.$refs[name]
			return refToValidate.validate()
		},
		customerData(model, isValid) {
			const me = this
			if (isValid) {
				me.formData = { ...me.formData, ...model }
				me.userEmail = me.formData.email
			}
		},
		configurationData(model, isValid) {
			const me = this
			if (isValid) {
				me.formData = { ...me.formData, ...model }
				const brand_id = uuidv4()
				const user_id = uuidv4()

				me.client = new FormData()
				me.client.append('name', me.formData.name_company)
				me.client.append('nit', me.formData.nit)
				me.client.append('logo', me.formData.logo)
				me.client.append(
					'number_central_offices',
					me.formData.number_central_offices,
				)
				me.client.append('number_users', me.formData.number_users)
				me.client.append('brand_id', brand_id)
				me.client.append('brand_name', me.formData.name_brand)
				me.client.append('brand_address', me.formData.address)
				me.client.append('brand_city_id', me.formData.city_id)
				me.client.append('brand_contact', me.formData.contact)
				me.client.append('brand_phone', me.formData.phone)
				me.client.append('brand_cellphone', me.formData.cellphone)
				me.client.append('brand_email', me.formData.email)
				me.client.append('brand_enabling_code', me.formData.enabling_code)
				me.client.append('brand_zone', me.formData.zone)
				me.client.append('brand_latitude', me.formData.latitude)
				me.client.append('brand_longitude', me.formData.longitude)
				me.client.append('brand_post', me.formData.post)
				me.client.append('brand_is_principal', true)
				me.client.append('user_name', me.formData.name)
				me.client.append('user_password', me.formData.password)
				me.client.append('user_profile', me.formData.profile_id)
				me.client.append('user_id', user_id)

				me.formSubmitted()
			}
		},
		async formSubmitted() {
			const me = this
			try {
				me.isBusy = true
				const uuid = me.uuid ? me.uuid : uuidv4()
				const option = me.uuid ? 'update' : 'save'

				await me.clientRepository[option](uuid, me.client)
				me.$router.push({ name: 'client' })
			} catch (err) {
				console.log(err.response)
				const { errors } = err.response.data
				let messageError = ''
				let title = ''

				if (errors.brand_email) {
					title = 'Error correo eletrónico'
					messageError = 'Correo electrónico ya esta en uso.'
				}

				me.$bvToast.toast(messageError, {
						title: title,
						toaster: 'b-toaster-bottom-right',
						variant: 'danger',
						solid: true,
						appendToast: true,
					})
			} finally {
				me.isBusy = false
			}
		},
		isLastStep() {
			if (this.$refs.wizard) {
				return this.$refs.wizard.isLastStep
			}
			return false
		},
		async loadCountries() {
			const me = this
			try {
				const res = await me.clientRepository.getAllCountry()
				me.countries = res.data
			} catch (error) {
				// console.log(error)
			}
		},
		async loadDepartaments() {
			const me = this
			try {
				const res = await me.clientRepository.getAllDepartament()
				me.departaments = res.data
			} catch (error) {
				// console.log(error)
			}
		},
		async loadCities(id) {
			const me = this
			try {
				me.isDisable = true
				const res = await me.clientRepository.getAllCitiesByDepartament(id)
				me.cities = res.data
			} catch (error) {
				me.isDisable = true
				// console.log(error)
			} finally {
				me.isDisable = false
			}
		},
		async loadClient(id) {
			const me = this
			try {
				const res = await me.clientRepository.find(id)
				const { brand } = res
				me.loadCities(brand.department_id)
				me.customerRecord = {
					...res,
					...brand,
					name_company: res.name,
					cellphone: brand.celphone,
					name_brand: brand.name,
				}
				me.clientRecord = {
					number_central_offices: res.number_central_offices,
					number_users: res.number_users,
				}
			} catch (error) {
				// console.log(error)
			}
		},
	},
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.card__ {
	box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3),
		0 4px 15px 0 rgba(133, 132, 132, 0.15);
}
</style>
