<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="title text-left mb-1">
          USUARIO PRINCIPAL
        </div>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Usuario*"
          label-for="name1"
        >
          <b-form-input
            id="name1"
            v-model="formData.name"
            placeholder="Usuario"
            disabled
            @blur="$v.formData.name.$touch()"
          />
          <span
            v-if="$v.formData.name.$error && !$v.formData.name.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Contraseña*"
          label-for="password"
        >
          <b-form-input
            id="password"
            v-model="formData.password"
            type="password"
            placeholder="Contraseña"
            @blur="$v.formData.password.$touch()"
          />
          <span
            v-if="!record && $v.formData.password.$error && !$v.formData.password.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Número de usuarios permitidos*"
          label-for="number_users"
        >
          <b-form-input
            id="number_users"
            v-model="formData.number_users"
            type="number"
            placeholder="Número de usuarios permitidos"
            @blur="$v.formData.number_users.$touch()"
          />
          <span
            v-if="$v.formData.number_users.$error && !$v.formData.number_users.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Número de sedes permitidas*"
          label-for="number_central_offices"
        >
          <b-form-input
            id="number_central_offices"
            v-model="formData.number_central_offices"
            type="number"
            placeholder="Número de sedes permitidas"
            @blur="$v.formData.number_central_offices.$touch()"
          />
          <span
            v-if="$v.formData.number_central_offices.$error && !$v.formData.number_central_offices.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Rol*"
          label-for="rol"
        >
          <v-select
            id="rol"
            v-model="formData.profile_id"
            :options="profiles"
            :reduce="option => option.id"
            label="name"
            placeholder="Seleccione rol"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
          <span
            v-if="$v.formData.profile_id.$error && !$v.formData.profile_id.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <div class="title text-left mb-1">
          SELECCIONE MÓDULOS HABILITADOS
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
    BFormGroup,
    BCol,
    BFormInput,
    BRow,
  } from 'bootstrap-vue'
import { required } from 'vuelidate/lib/validators'
import vSelect from 'vue-select'

export default {
  inject: ['typeProfileRepository'],
  components: {
    BFormGroup,
    BCol,
    BFormInput,
    BRow,
    vSelect,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
    userEmail: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        number_central_offices: '',
        number_users: '',
        password: '',
        name: '',
        profile_id: '',
      },
      isBusy: false,
      profiles: [],
    }
  },
  watch: {
    record(newData) {
      this.formData = { ...this.formData, ...newData }
    },
    userEmail(newData) {
      const me = this
      me.formData.name = newData
    },
  },
  validations() {
    const me = this
    const defaultValidation = {
      number_central_offices: {
        required,
      },
      number_users: {
        required,
      },
      name: {
        required,
      },
      profile_id: {
        required,
      },
    }

    return me.record ?
      {
        formData: { ...defaultValidation },
      } : {
        formData: {
          password: {
            required,
          },
          ...defaultValidation,
        },
      }
  },
  mounted() {
    this.loadProfiles()
  },
  methods: {
    validate() {
      const me = this
      me.$v.formData.$touch()
      const isValid = !me.$v.formData.$invalid

      me.$emit('on-validate', me.formData, isValid)
      return isValid
    },
    async loadProfiles() {
      const me = this
      try {
        const { data } = await me.typeProfileRepository.getAll()

        data.forEach(el => {
          if (el.id !== '2734e277-e007-4b55-8ec8-21dc99a59d04') {
            me.profiles.push({ id: el.id, name: el.rol + ' ' + el.position })
          }
        })
      } catch (error) {
        // console.log(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .title {
    font-size: 16px;
    font-weight: 700;
    color: #636363;
  }
  .imagen
  {
    border: 1px solid #808080;
    width: 150px;
    height: 150px;
    border-radius: .3rem;
    background-color: #fff;
    cursor: pointer;
  }

  .image__preview img
  {
    width: 150px;
    height: 150px;
  }

  .btn__delete
  {
    background-color: #7367F0;
    color: #fff;
    font-size: 16px;
    padding: 3px 8px 3px 8px;
    border-radius: 50%;
    border: none;
    letter-spacing: 0.5px;
    position: absolute;
  }
</style>
