<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="text-center mb-2">
          <div class="image__preview mb-1">
            <template v-if="!photo">
              <img src="@/assets/images/default.png">
            </template>
            <template v-else>
              <img :src="photo">
              <button
                class="btn__delete"
                @click="RemovePhoto"
              >
                <feather-icon icon="Trash2Icon" />
              </button>
            </template>
          </div>
          <div>
            <b-button
              type="button"
              variant="primary"
              @click="$refs.fileInput.click()"
            >
              <feather-icon icon="CameraIcon" />
              Subir logo
              <input
                id="file"
                ref="fileInput"
                type="file"
                class="d-none"
                name="file0"
                accept=".jpg, .png, .jpeg"
                @change="fileChange"
              >
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Nombre de la empresa*"
          label-for="name_company"
        >
          <b-form-input
            id="name_company"
            v-model="$v.formData.name_company.$model"
            placeholder="Nombre de la empresa"
            @blur="$v.formData.name_company.$touch()"
          />
          <span
            v-if="
              $v.formData.name_company.$error &&
                !$v.formData.name_company.required
            "
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="NIT*"
          label-for="nit"
        >
          <b-form-input
            id="nit"
            v-model="$v.formData.nit.$model"
            placeholder="Nit de la empresa"
            @blur="$v.formData.nit.$touch()"
          />
          <span
            v-if="$v.formData.nit.$error && !$v.formData.nit.required"
            class="help-block text-danger"
          >Campo requerido</span>
          <span
            v-if="$v.formData.nit.$error && !$v.formData.nit.numeric"
            class="help-block text-danger"
          >Ingrese solo números.</span>
          <span
            v-if="$v.formData.nit.$error && !$v.formData.nit.maxLength"
            class="help-block text-danger"
          >
            El nit debe tener máximo
            {{ $v.formData.nit.$params.maxLength.max }} dígitos.
          </span>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <div class="title text-left mb-1">
          INFORMACIÓN SEDE PRINCIPAL
        </div>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Nombre de la sede*"
          label-for="name_brand"
        >
          <b-form-input
            id="name_brand"
            v-model="$v.formData.name_brand.$model"
            placeholder="Nombre de la empresa"
            @blur="$v.formData.name_brand.$touch()"
          />
          <span
            v-if="
              $v.formData.name_brand.$error && !$v.formData.name_brand.required
            "
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Código de habilitación"
          label-for="enabling_code"
        >
          <b-form-input
            id="enabling_code"
            v-model="$v.formData.enabling_code.$model"
            type="number"
            placeholder="Código de habilitación"
            @blur="$v.formData.enabling_code.$touch()"
          />
          <span
            v-if="
              $v.formData.enabling_code.$error &&
                !$v.formData.enabling_code.required
            "
            class="help-block text-danger"
          >Campo requerido</span>
          <span
            v-if="
              $v.formData.enabling_code.$error &&
                !$v.formData.enabling_code.numeric
            "
            class="help-block text-danger"
          >Ingrese solo números.</span>
          <span
            v-if="
              $v.formData.enabling_code.$error &&
                !$v.formData.enabling_code.maxLength
            "
            class="help-block text-danger"
          >
            El Código debe tener máximo
            {{ $v.formData.enabling_code.$params.maxLength.max }} dígitos.
          </span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Dirección*"
          label-for="address"
        >
          <b-form-input
            id="address"
            v-model="$v.formData.address.$model"
            placeholder="Dirección"
            @blur="$v.formData.address.$touch()"
          />
          <span
            v-if="$v.formData.address.$error && !$v.formData.address.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Teléfono*"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            v-model="$v.formData.phone.$model"
            type="number"
            placeholder="Teléfono"
            @blur="$v.formData.phone.$touch()"
          />
          <span
            v-if="$v.formData.phone.$error && !$v.formData.phone.required"
            class="help-block text-danger"
          >Campo requerido</span>
          <span
            v-if="$v.formData.phone.$error && !$v.formData.phone.numeric"
            class="help-block text-danger"
          >Ingrese solo números.</span>
          <span
            v-if="$v.formData.phone.$error && !$v.formData.phone.minLength"
            class="help-block text-danger"
          >
            El teléfono debe tener mínimo
            {{ $v.formData.phone.$params.minLength.min }} dígitos.
          </span>
          <span
            v-if="$v.formData.phone.$error && !$v.formData.phone.maxLength"
            class="help-block text-danger"
          >
            El teléfono debe tener máximo
            {{ $v.formData.phone.$params.maxLength.max }} dígitos.
          </span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Celular*"
          label-for="cellphone"
        >
          <b-form-input
            id="cellphone"
            v-model="$v.formData.cellphone.$model"
            type="number"
            placeholder="Celular"
            @blur="$v.formData.cellphone.$touch()"
          />
          <span
            v-if="
              $v.formData.cellphone.$error && !$v.formData.cellphone.required
            "
            class="help-block text-danger"
          >Campo requerido</span>
          <span
            v-if="
              $v.formData.cellphone.$error && !$v.formData.cellphone.numeric
            "
            class="help-block text-danger"
          >Ingrese solo números.</span>
          <span
            v-if="
              $v.formData.cellphone.$error && !$v.formData.cellphone.minLength
            "
            class="help-block text-danger"
          >
            El celular debe tener mínimo
            {{ $v.formData.cellphone.$params.minLength.min }} dígitos.
          </span>
          <span
            v-if="
              $v.formData.cellphone.$error && !$v.formData.cellphone.maxLength
            "
            class="help-block text-danger"
          >
            El celular debe tener máximo
            {{ $v.formData.cellphone.$params.maxLength.max }} dígitos.
          </span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Contacto*"
          label-for="contact"
        >
          <b-form-input
            id="contact"
            v-model="$v.formData.contact.$model"
            placeholder="Contacto"
            @blur="$v.formData.contact.$touch()"
          />
          <span
            v-if="$v.formData.contact.$error && !$v.formData.contact.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Cargo*"
          label-for="post"
        >
          <b-form-input
            id="post"
            v-model="$v.formData.post.$model"
            placeholder="Cargo"
            @blur="$v.formData.post.$touch()"
          />
          <span
            v-if="$v.formData.post.$error && !$v.formData.post.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Correo electrónico*"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="$v.formData.email.$model"
            type="email"
            placeholder="Correo electrónico"
            @blur="$v.formData.email.$touch()"
          />
          <span
            v-if="$v.formData.email.$error && !$v.formData.email.required"
            class="help-block text-danger"
          >Campo requerido</span>
          <span
            v-if="$v.formData.email.$error && !$v.formData.email.email"
            class="help-block text-danger"
          >Ingrese una dirección de correo válida.</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="País*"
          label-for="country"
        >
          <v-select
            id="country"
            v-model="country_id"
            :options="countries"
            :reduce="(country) => country.id"
            label="name"
            disabled
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Departamento*"
          label-for="departament"
        >
          <v-select
            id="departament"
            v-model="formData.department_id"
            :options="departaments"
            :reduce="(departament) => departament.id"
            label="name"
            placeholder="Seleccione departamento"
            @input="loadCities"
            @blur="$v.formData.department_id.$touch()"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
          <span
            v-if="
              $v.formData.department_id.$error &&
                !$v.formData.department_id.required
            "
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Ciudad*"
          label-for="city"
        >
          <v-select
            id="city"
            v-model="formData.city_id"
            :options="cities"
            :reduce="(city) => city.id"
            label="name"
            :disabled="isDisable"
            placeholder="Seleccione ciudad"
            @blur="$v.formData.city_id.$touch()"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
          <span
            v-if="$v.formData.city_id.$error && !$v.formData.city_id.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Zona*"
          label-for="zone"
        >
          <b-form-input
            id="zone"
            v-model="$v.formData.zone.$model"
            placeholder="Zona"
          />
          <span
            v-if="$v.formData.zone.$error && !$v.formData.zone.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Latitud*"
          label-for="latitude"
        >
          <b-form-input
            id="latitude"
            v-model="$v.formData.latitude.$model"
            type="number"
            placeholder="Latitud"
          />
          <span
            v-if="$v.formData.latitude.$error && !$v.formData.latitude.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Longitud*"
          label-for="longitude"
        >
          <b-form-input
            id="longitude"
            v-model="$v.formData.longitude.$model"
            type="number"
            placeholder="Longitud"
          />
          <span
            v-if="
              $v.formData.longitude.$error && !$v.formData.longitude.required
            "
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
 BFormGroup, BCol, BFormInput, BRow, BButton,
} from 'bootstrap-vue'
import {
	required,
	email,
	numeric,
	minLength,
	maxLength,
} from 'vuelidate/lib/validators'
import vSelect from 'vue-select'
import { API_ROUTES } from '../../config/routes/api'

export default {
	components: {
		BFormGroup,
		BCol,
		BFormInput,
		BRow,
		BButton,
		vSelect,
	},
	props: {
		countries: {
			type: Array,
			default: null,
		},
		departaments: {
			type: Array,
			default: null,
		},
		cities: {
			type: Array,
			default: null,
		},
		isDisable: {
			type: Boolean,
			default: null,
		},
		record: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			formData: {
				name_company: '',
				nit: '',
				address: '',
				logo: '',
				enabling_code: '',
				city_id: null,
				phone: '',
				contact: '',
				cellphone: '',
				email: '',
				latitude: '',
				longitude: '',
				post: '',
				zone: '',
				department_id: null,
				name_brand: '',
			},
			photo: '',
			country_id: 1,
		}
	},
	watch: {
		record() {
			this.fillFormData()
		},
	},
	validations: {
		formData: {
			name_brand: {
				required,
			},
			nit: {
				required,
				numeric,
				maxLength: maxLength(15),
			},
			address: {
				required,
			},
			enabling_code: {
				numeric,
				maxLength: maxLength(20),
			},
			phone: {
				required,
				numeric,
				minLength: minLength(7),
				maxLength: maxLength(15),
			},
			contact: {
				required,
			},
			cellphone: {
				required,
				numeric,
				minLength: minLength(7),
				maxLength: maxLength(15),
			},
			email: {
				required,
				email,
			},
			post: {
				required,
			},
			city_id: {
				required,
			},
			department_id: {
				required,
			},
			latitude: {
				required,
			},
			longitude: {
				required,
			},
			zone: {
				required,
			},
			name_company: {
				required,
			},
		},
	},
	methods: {
		fileChange(e) {
			const me = this
			// Recoger el fichero
			const file = e.target.files[0]
			me.formData.logo = file
			const { type } = file
			if (type != 'image/jpeg' && type != 'image/jpg' && type != 'image/png') {
				me.$bvToast.toast('¡Formato no permitido!', {
					title: 'Los formatos permitidos son .jpg .jpeg .png',
					toaster: 'b-toaster-bottom-right',
					variant: 'danger',
					solid: true,
					appendToast: true,
				})
				me.RemovePhoto()
			} else {
				const reader = new FileReader()
				reader.onload = () => {
					me.photo = reader.result
				}
				reader.readAsDataURL(file)
			}
		},
		RemovePhoto() {
			this.formData.logo = ''
			this.photo = ''
		},
		validate() {
			const me = this
			me.$v.formData.$touch()
			const isValid = !me.$v.formData.$invalid

			me.$emit('on-validate', me.formData, isValid)
			return isValid
		},
		loadCities(id) {
			const me = this
			me.formData.city_id = null
			me.$emit('loadCities', id)
		},
		fillFormData() {
			const me = this
			me.formData = { ...me.formData, ...me.record }
			const img =
				me.formData.logo !== ''
					? (me.photo = API_ROUTES.images.image + me.formData.logo)
					: (me.photo = '')
		},
	},
}
</script>

<style lang="scss" scoped>
.title {
	font-size: 16px;
	font-weight: 700;
	color: #636363;
}
.imagen {
	border: 1px solid #808080;
	width: 150px;
	height: 150px;
	border-radius: 0.3rem;
	background-color: #fff;
	cursor: pointer;
}

.image__preview img {
	width: 150px;
	height: 150px;
}

.btn__delete {
	background-color: #7367f0;
	color: #fff;
	font-size: 16px;
	padding: 3px 8px 3px 8px;
	border-radius: 50%;
	border: none;
	letter-spacing: 0.5px;
	position: absolute;
}
</style>
